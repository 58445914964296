<i18n src="@/i18n/misc.json"></i18n>
<i18n src="@/i18n/channel.json"></i18n>

<template lang="pug">
    .subscriber.view
        .content-wrapper
            .header
                .h1 Новый отправитель
                el-button(@click="$router.push({ name: 'subscriber.edit' })" icon="el-icon-plus" type="primary" size="small" plain) Add new
            .content.full-width.transparent
                el-form(:model="subscriber_form", @keydown.enter.native.prevent="saveSubscriber" :rules="subscriber_form_rules" ref="form" label-position="top")
                    .form-row
                        el-form-item(prop="channel_alias" label="Канал")
                            el-select(v-model="subscriber_form.channel_alias" placeholder="Канал")
                                el-option(v-for="(channel, alias) in $t('channel.list')" :label="channel" :value="alias" :key="alias" )
                        el-form-item(prop="_sender_name", label="Название")
                            el-input(v-model="subscriber_form._sender_name" type="text")
                    el-form-item
                        el-button(@click="saveSubscriber", type="primary") {{ $t('misc.buttons.save') }}

</template>

<script>
    import { mapActions } from 'vuex'
    import { GET_SENDERS, SAVE_SUBSCRIBER } from "@/store/types"

    const default_subscriber_form = ()=>{
        return {
            subscriber_uid: undefined,
            channel_alias: 'sms',
            phone: '',
            sender_name: '',
            confirmation_code: '',
        }
    }

    export default {
        metaInfo() {
            return {
                title: this.title
            }
        },
        data() {
            return {
                senders: [
                    {
                        id: 1,
                        'name': 'Pyatyorochka',
                    }
                ],
                subscriber: {},
                subscriber_form: default_subscriber_form(),
                subscriber_form_rules: {
                    channel_alias: [
                        { required: true, message: 'Выберите канал', trigger: 'blur' },
                    ],
                    sender_name: [
                        { required: true, message: 'Выберите отправителя', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: 'Укажите номер телефона', trigger: 'blur' },
                    ],
                },
            }
        },
        computed: {
            title(){
                return 'New subscriber'
            },
        },
        methods: {
            ...mapActions([
                GET_SENDERS,
                SAVE_SUBSCRIBER,
            ]),
            saveSubscriber(){
                this.$refs['new_subscriber_form'].validate((valid) => {
                    if (valid) {
                        return this.SAVE_SUBSCRIBER({ ...this.subscriber_form, ...{ subscriber_uid: this.subscriber.uid } }).then(()=>{
                            this.subscriber_form = default_subscriber_form()
                        }).catch((error)=>{
                            this.$root.pushAppMessages(error.response.data)
                        })
                    } else return false
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .subscriber{
        .header{
            display: flex;
            align-items: center;
            padding-bottom: 1.5rem;

            .h3{
                margin-left: 1rem;
            }
        }
        .content-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1.5rem;
        }
        .form-row{
            > * {
                margin-right: 1rem;

                &:last-child{
                    margin-left: 0;
                }
            }
        }
    }
</style>
